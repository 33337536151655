body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding-top: constant(safe-area-inset-top); /* iOS 11.0 */
  padding-top: env(safe-area-inset-top); /* iOS 11.2 */
  padding-bottom: constant(safe-area-inset-bottom); /* iOS 11.2 */
  padding-bottom: env(safe-area-inset-bottom); /* iOS 11.2 */
}


/* Hide scrollbar for Chrome, Safari and Opera */
*::-webkit-scrollbar {
  display: none;
}

*:focus {
  outline: none;
}



code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
input, label {
  font-family:Assistant;
}
.clickable {
  cursor: pointer !important;
}

mark{
    background-color: #FF625026;
    color: #707070;
    padding: 0px 0px 0px 0px;
}

a {
  text-decoration: none;
  color: #FF6250;
}

* {
   -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  box-sizing: border-box;
}


.mamakoo-blurred-place-name {

}